import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type DataFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type V2_MetaFunction } from
'@remix-run/node';
import {
  Form,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useSubmit } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { useRef } from 'react';
import { Confetti } from './components/confetti.tsx';
import { Button } from './components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger } from
'./components/ui/dropdown-menu.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import { Toaster } from './components/ui/toaster.tsx';
// import { useTheme } from './routes/resources+/theme/index.tsx'
import { getTheme } from './routes/resources+/theme/theme-session.server.ts';
// import fontStylestylesheetUrl from './styles/font.css'
import "./styles/global.css?__remix_sideEffect__";
import tailwindStylesheetUrl from './styles/tailwind.css';
import { authenticator, getUserId } from './utils/auth.server.ts';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { getFlashSession } from './utils/flash-session.server.ts';
import { combineHeaders, getDomainUrl, getUserImgSrc } from './utils/misc.ts';
import { useNonce } from './utils/nonce-provider.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { useToast } from './utils/useToast.tsx';
import { useOptionalUser, useUser } from './utils/user.ts';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' }, (
  // Preload CSS as a resource to avoid render blocking
  // { rel: 'preload', href: fontStylestylesheetUrl, as: 'style' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  } as const),
  { rel: 'preload', href: tailwindStylesheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  // { rel: 'stylesheet', href: fontStylestylesheetUrl },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@400;600;800&display=swap'
  },
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: V2_MetaFunction = () => {
  return [
  { title: 'Hope Point Church' },
  {
    name: 'description',
    content: 'A Church in Chesterfield & Midlothian, VA'
  }];

};

export async function loader({ request }: DataFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUnique({
      where: { id: userId },
      select: { id: true, name: true, username: true, imageId: true }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await authenticator.logout(request, { redirectTo: '/' });
  }
  const { flash, headers: flashHeaders } = await getFlashSession(request);

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        session: {
          theme: await getTheme(request)
        }
      },
      ENV: getEnv(),
      flash,
      gaTrackingId: process.env.GA_TRACKING_ID
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        flashHeaders
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const user = useOptionalUser();
  // const theme = useTheme()
  useToast(data.flash?.toast);

  return (
    <html lang="en" className={`light h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<meta property="og:title" content="Hope Point Church" />
				<meta
          property="og:description"
          content="Stay connected with Hope Point" />

				<meta
          property="og:image"
          content="https://hopepoint.info/img/hope-point-og.jpg" />

				<meta property="og:url" content="https://hopepoint.info" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Hope Point Church" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta property="og:image:alt" content="Hope Point Church" />
				<meta property="twitter:card" content="summary" />
				<meta property="twitter:site" content="@HOPchesterfield" />
				<meta property="twitter:url" content="https://hopepoint.info" />
				<meta property="twitter:title" content="Hope Point Church" />
				<meta
          property="twitter:description"
          content="Stay connected with Hope Point" />

				<meta
          property="twitter:image"
          content="https://hopepoint.info/img/hope-point-square.jpg" />

				<meta property="twitter:image:alt" content="Hope Point Church" />
				<Links />
			</head>
			<body>
				<div className="min-w-screen min-h-screen bg-[#ededea]">
					<header className="mx-auto max-w-xl py-6">
						<nav className="items-w-radix-context-menu-trigger-width ml-2 flex w-full justify-between gap-x-4">
							<div className="flex items-center gap-x-4">
								<Link to="/">
									<img
                    src="/img/logo_icon_black.png"
                    alt="logo"
                    className="h-12" />

								</Link>
								<h1 className="text-xl font-bold uppercase text-black">
									Hope Point Church
								</h1>
							</div>
							<div className="flex items-center gap-10">
								{user ? <UserDropdown /> : null}
							</div>
						</nav>
					</header>

					<main>
						<Outlet />
					</main>

					{/* <div className="container flex justify-between pb-5">
            <Link to="/">
            	<div className="font-light">epic</div>
            	<div className="font-bold">notes</div>
            </Link>
            <ThemeSwitch userPreference={data.requestInfo.session.theme} />
            </div> */}
				</div>
				<Confetti confetti={data.flash?.confetti} />
				<Toaster />
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`
          }} />

				<LiveReload nonce={nonce} />
				{process.env.NODE_ENV === 'development' || !data.gaTrackingId ? null :
        <>
						<script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${data.gaTrackingId}`} />

						<script
            async
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${data.gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `
            }} />

					</>}

			</body>
		</html>);

}
export default withSentry(App);

function UserDropdown() {
  const user = useUser();
  const submit = useSubmit();
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary">
					<Link
            to={`/users/${user.username}`}
            // this is for progressive enhancement
            onClick={(e) => e.preventDefault()}
            className="flex items-center gap-2">

						<img
              className="h-8 w-8 rounded-full object-cover"
              alt={user.name ?? user.username}
              src={getUserImgSrc(user.imageId)} />

						<span className="text-body-sm font-bold">
							{user.name ?? user.username}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}/notes`}>
							<Icon className="text-body-md" name="pencil-2">
								Edit App
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
            asChild
            // this prevents the menu from closing before the form submission is completed
            onSelect={(event) => {
              event.preventDefault();
              submit(formRef.current);
            }}>

						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>);

}